import { render, staticRenderFns } from "./main-header.vue?vue&type=template&id=c48922b8&"
import script from "./main-header.vue?vue&type=script&lang=js&"
export * from "./main-header.vue?vue&type=script&lang=js&"
import style0 from "./main-header.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainLogo: require('/opt/new.yaravia.ru/src/components/main-logo.vue').default,MainContacts: require('/opt/new.yaravia.ru/src/components/main-contacts.vue').default})
