//
//
//
//
//
//
//
//
//
//

export default {
  name: 'main-contacts',
  data() {
    return {
      phones: {
        questions: {
          phone: '+7 4852 43-18-00',
          label: 'справочная аэропорта',
        },
        tickets: {
          phone: '+7 4852 68-14-23',
          label: 'авиабилеты',
        },
      },
    };
  },
  methods: {
    getPhoneLink(phone) {
      return phone.replace(/ /g, '').replace(/-/g, '');
    },
  },
};
